import React, { Component, Fragment } from 'react';
import { ChevronDownIcon, SearchIcon, SortAscendingIcon, PlusIcon, ChevronUpIcon, TrashIcon, UserGroupIcon, IdentificationIcon, ArrowLeftIcon, ArrowRightIcon, CheckIcon, CalendarIcon, CheckCircleIcon, ExclamationCircleIcon, AdjustmentsIcon, ClipboardListIcon, LockClosedIcon, LockOpenIcon, MinusCircleIcon, ArrowDownIcon } from '@heroicons/react/outline'
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import TopNavigationAgent from '../moduleFiles/topNavigationAgent';
import { UserIcon } from '@heroicons/react/outline';
import WarningModalTailwind from '../moduleFiles/warningModalTailwind';
import { Menu, Transition } from '@headlessui/react';
import cn from "classnames";
import classNames from 'classnames';
import moment from "moment";
import { clientRegister } from '../services/clientRegister';
import CreateClient from '../moduleFiles/createClient';
import SlideoutTailwind from '../moduleFiles/slideoutTailwind';
import { Line, Doughnut } from 'react-chartjs-2';
import { isNumber } from '@turf/turf';
import TopNavigationAgentTiny from '../moduleFiles/topNavigationAgentTiny';

class ClientAccountList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            clients: [],
            managers: [],
            loading: true,
            loading_partial: false,
            metric: "spend",
            meta: {},
            chart: {},
            limit: 10,
            page: 1,
            sort: "name",
            direction: "descending",
            search: "",
            columns: [
                { name: 'Name', value: 'name', min_width: '350px', border: true },
                { name: 'Client', value: 'client', min_width: '450px', border: true },
                { name: 'Spend', value: 'spend', min_width: '200px', border: true },
                { name: 'Impressions', value: 'impressions', min_width: '200px', border: true },
                { name: 'Click', value: 'clicks', min_width: '200px', border: true },
                { name: 'CTR', value: 'ctr', min_width: '200px', border: true },
                { name: 'CPC', value: 'cpc', min_width: '200px', border: true },
                { name: 'CPM', value: 'cpm', min_width: '200px', border: true }
            ],
            client: {},
            advancedFilters: [
                {
                    maxWidth: true,
                    type: "timeSpan",
                    placeholder: "This month",
                    defaultOptions: [],
                    options: [
                        { id: 1, name: "This month", value: "this_month" },
                        { id: 2, name: "Last month", value: "last_month" },
                        { id: 3, name: "Two months ago", value: "2_months" },
                        { id: 4, name: "Three months ago", value: "3_months" }
                    ],
                    selected: { id: 1, name: "This month", value: "this_month" },
                    loading: false
                },
                {
                    maxWidth: true,
                    type: "channel",
                    placeholder: "All channels (default)",
                    defaultOptions: [],
                    options: [
                        { id: 1, name: "All channels (default)", value: "all" },
                        { id: 2, name: "Facebook", value: "facebook" },
                        { id: 3, name: "Tiktok", value: "tiktok" },
                        { id: 4, name: "Linkedin", value: "linkedin" },
                        { id: 5, name: "Google", value: "google" },
                        { id: 5, name: "Bing", value: "bing" }
                    ],
                    selected: { id: 1, name: "All channels (default)", value: "all" }
                }
            ],
            doughnuts: {},
            disable_chart: false,
        };
    }

    componentWillMount() {
    }

    componentDidMount() {
        this.functions.client();
    }

    functions = {
        client: async () => {
            await this.promisedSetState({
                client: clientRegister.get()
            });
            this.functions.listClientAccounts(true);
        },
        listClientAccounts: async (init) => {
            await this.promisedSetState({ loading: init, loading_partial: !init });
            try {
                let params = "";
                this.state.advancedFilters.map((filter) => {
                    params = params + "&" + filter.type + "=" + filter.selected.value;
                });
                let response = await this.calls.listClientAccounts(params);
                await this.promisedSetState({
                    managers: response.data,
                    meta: response.meta
                });
            } catch (error) { }
            await this.promisedSetState({ loading: false, loading_partial: false });
        },
        sleep: (ms) => {
            return new Promise(resolve => setTimeout(resolve, ms));
        },
        clientUpdated: async (client) => {
            await this.promisedSetState({
                client: client
            });
            this.functions.listClientAccounts();
        }
    };

    calls = {
        listClientAccounts: (params) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/listClientAccounts?page=" + this.state.page + "&limit=" + this.state.limit + "&sortBy=" + this.state.sort + "&orderBy=" + this.state.direction + (this.state.client && this.state.client.id !== 0 ? "&client=" + this.state.client.id : "") + params;
            return apiRegister.call(options, url);
        }
    }

    renders = {
        convertToDecimal: (value) => {
            try {
                value = +value;
                return value.toFixed(2);
            } catch (error) {
                return value;
            }
        },
        pacing_percentage: (item) => {
            try {
                let days_so_far = item.days;
                let total_days = item.total_days;
                //PACING SPEND
                let avereage_spend_per_day = +item.max_budget.budget / +total_days;
                let pacing_spend = +avereage_spend_per_day * +days_so_far;
                let percentage_pacing = ((pacing_spend * 100) / +item.max_budget.budget);
                let pacing_percentage = percentage_pacing;
                //ACTUAL SPEND
                let pacing_spend_2 = +item.max_budget.spend;
                let percentage_pacing_2 = ((pacing_spend_2 * 100) / +item.max_budget.budget);
                let pacing_percentage_2 = percentage_pacing_2;
                let percentage = pacing_percentage_2 - pacing_percentage;
                if (isNumber(percentage)) {
                    return percentage.toFixed(2);
                } else {
                    return "-";
                }

            } catch (error) {
                return "-";
            }
        },
        pacing_perfomance: (item) => {
            try {
                if (this.renders.pacing_percentage(item) !== "-") {
                    return +this.renders.pacing_percentage(item)
                } else {
                    return "-";
                }
            } catch (error) {
                return "-";
            }
        },
        color: (item) => {
            try {
                if (item !== "-") {
                    if (+item < 0) {
                        item = +item * -1;
                    }
                    if (+item <= 5) {
                        return "text-green-600 bg-green-100";
                    } else if (+item > 5 && +item < 11) {
                        return "text-yellow-600 bg-yellow-100";
                    } else if (+item >= 11) {
                        return "text-red-500 bg-red-100";
                    }
                } else return "";
            } catch (error) {
                return "";
            }
        },
        colorBorder: (item) => {
            try {
                if (item !== "-") {
                    if (+item < 0) {
                        item = +item * -1;
                    }
                    if (+item <= 5) {
                        return "border-green-600";
                    } else if (+item > 5 && +item < 11) {
                        return "border-yellow-600";
                    } else if (+item >= 11) {
                        return "border-red-500";
                    }
                } else return "";
            } catch (error) {
                return "";
            }
        },
        showMaxBudget: () => {
            let show = true;
            this.state.advancedFilters.map((item) => {
                if (item.type == "timeSpan" && item.selected.value !== "this_month") {
                    show = false;
                }
            });
            return show;
        },
        showChart: () => {
            let show = true;
            this.state.advancedFilters.map((item) => {
                if (item.type == "showChart" && item.selected.value == "enabled") {
                    show = false;
                }
            });
            return show;
        },
        addCommas(nStr) {
            nStr += '';
            var x = nStr.split('.');
            var x1 = x[0];
            var x2 = x.length > 1 ? '.' + x[1] : '';
            var rgx = /(\d+)(\d{3})/;
            while (rgx.test(x1)) {
                x1 = x1.replace(rgx, '$1' + ' ' + '$2');
            }
            return x1 + x2;
        }
    };

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {

        return (
            <div className="relative flex flex-1 flex-col">

                <TopNavigationAgentTiny
                    onAdvancedFilter={async (value, type) => {
                        this.state.advancedFilters = this.state.advancedFilters.map((item) => {
                            if (item.type === type) {
                                item.selected = value;
                            }
                            return item;
                        });
                        this.promisedSetState({
                            page: 1,
                            limit: 10,
                            advancedFilters: this.state.advancedFilters,
                        });
                        this.functions.listClientAccounts();
                    }}
                    onButton={(type) => {
                        if (type === "new") {
                            this.setState({
                                create_order: true
                            })
                        }
                    }}
                    onLimit={async (value) => {
                        await this.promisedSetState({
                            page: 1,
                            limit: value
                        })
                        this.functions.listClientAccounts();
                    }}
                    onNext={async (value) => {
                        await this.promisedSetState({
                            page: value
                        })
                        this.functions.listClientAccounts();
                    }}
                    onPrevious={async (value) => {
                        await this.promisedSetState({
                            page: value
                        })
                        this.functions.listClientAccounts();
                    }}
                    onSearch={async (value) => {
                        await this.promisedSetState({
                            loading_partial: true,
                            search: value
                        });
                        setTimeout(async () => {
                            if (value === this.state.search) {
                                await this.promisedSetState({
                                    page: 1
                                });
                                this.functions.listClientAccounts(false, value);
                            }
                        }, 400);
                    }}
                    limit={this.state.limit}
                    page={this.state.page}
                    total={this.state.meta ? this.state.meta.total : 0}
                    showPaginaton={!this.state.loading}
                    showPaginationSearch={!this.state.loading}
                    showAdvanced={!this.state.loading}
                    advancedFilter={true}
                    advancedFilters={this.state.advancedFilters}
                    filters={[]}
                    hideUserDropdown={true}
                    buttonTypes={{}}
                    buttonLoaders={{}}
                    tagType={"order"}
                    searchInputValue={this.state.search}
                    search={this.state.search}
                    client={{}}
                />

                {/*LOADING VIEW*/}
                {
                    this.state.loading &&
                    <div className="min-h-screen flex justify-center items-center flex-col flex-1 w-full">
                        <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        <div className="font-semibold mt-3">Loading accounts ...</div>
                    </div>
                }

                {
                    !this.state.loading &&
                    <div className="p-4 flex flex-1 flex-col">

                        {
                            this.state.managers.length === 0 &&
                            <div className='flex flex-col h-64 items-center justify-center bg-white rounded-lg shadow'>
                                <div className="mt-2 text-ls font-medium text-gray-500">No accounts available</div>
                            </div>
                        }

                        {
                            this.state.managers.length > 0 &&
                            <div className={"shadow bg-white w-full flex flex-col overflow-hidden h-full rounded-lg"}>

                                {/*TABLE*/}
                                {
                                    this.state.managers.length > 0 &&
                                    <div className="relative h-full flex flex-1">
                                        <div className="overflow-auto absolute table-overflow w-full h-full">

                                            {/*LOADER PARTIAL*/}
                                            {
                                                this.state.loading_partial &&
                                                <div className="text-center absolute top-0 bottom-0 z-51 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                                    <div style={{ borderTopColor: "transparent" }} class="w-12 h-12 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                                </div>
                                            }

                                            <table className="w-full relative divide-y divide-gray-300 border-gray-300">
                                                <thead className="sticky z-60 bg-white top-0 shadow">
                                                    <tr>
                                                        {this.state.columns.filter(col => { return !col.disabled }).map((item, index) => {
                                                            return (
                                                                <th
                                                                    onClick={async () => {
                                                                        if (!item.noSort) {
                                                                            await this.promisedSetState({
                                                                                page: 1,
                                                                                sort: item.value,
                                                                                direction: this.state.direction === 'ascending' ? 'descending' : 'ascending'
                                                                            });
                                                                            this.functions.listClientAccounts();
                                                                        }
                                                                    }}
                                                                    style={index === 0 ? { left: 0, whiteSpace: "nowrap" } : { whiteSpace: "nowrap" }}
                                                                    scope="col"
                                                                    className={(item.value == this.state.sort ? "bg-gray-100 bg-opacity-100 cursor-pointer" : (!item.noSort ? "bg-white hover:bg-gray-100 hover:bg-opacity-100 cursor-pointer" : "")) + " border-r px-3 py-3 border-gray-300 text-left text-xxs font-medium whitespace-nowrap text-gray-700 uppercase tracking-wider " + (index === 0 && " sticky ")}
                                                                >
                                                                    <div className="flex flex-row items-center justify-between">
                                                                        <div className="mr-4">{item.name}</div>
                                                                        {
                                                                            !item.noSort &&
                                                                            <div className="flex justify-start flex-col">
                                                                                <ArrowDownIcon className={`h-3 w-3 text-gray-900 opacity-0 ${this.state.sort === item.value && "opacity-100"} ${this.state.sort === item.value && this.state.direction === "ascending" && "transform rotate-180"}`} />
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </th>
                                                            )
                                                        })}
                                                    </tr>
                                                </thead>
                                                <tbody className="bg-white divide-gray-300">
                                                    {
                                                        this.state.managers.map((item, index) => {
                                                            return (
                                                                <Fragment>
                                                                    <tr
                                                                        onMouseEnter={() => {
                                                                            item.hover = true;
                                                                            this.setState({
                                                                                managers: this.state.managers
                                                                            })
                                                                        }}
                                                                        onMouseLeave={() => {
                                                                            item.hover = false;
                                                                            this.setState({
                                                                                managers: this.state.managers
                                                                            })
                                                                        }}
                                                                        className="border-b" key={item.id}>
                                                                        <td style={{ zIndex: 0 }} className={((this.state.sort === 'name' || item.hover) ? "bg-gray-50 bg-opacity-100" : "bg-white bg-opacity-100") + " sticky left-0 border-r text-left px-3 py-4 whitespace-no-wrap font-semibold cursor-pointer hover:text-purple-500 items-center text-xs border-gray-300"}>
                                                                            <div className="flex flex-row items-center">
                                                                                <div className="pl-2">
                                                                                    {
                                                                                        (item.channel === "facebook") &&
                                                                                        <div
                                                                                            className="w-7 h-7 bg-facebook-500 rounded-full p-1.5 border-2 border-white -ml-2">
                                                                                            <div
                                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                style={{ backgroundImage: "url(" + require('../assets/images/facebook_icon.svg') + ")" }}></div>
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        (item.channel === "linkedin") &&
                                                                                        <div
                                                                                            className={'w-7 h-7 bg-linkedin-500 rounded-full p-1.5 border-2 border-white -ml-2'}>
                                                                                            <div
                                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                style={{ backgroundImage: "url(" + require('../assets/images/linkedin_icon.svg') + ")" }}></div>
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        (item.channel === "google") &&
                                                                                        <div
                                                                                            className={'w-7 h-7 bg-google-500 rounded-full p-1.5 border-2 border-white -ml-2'}>
                                                                                            <div
                                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                style={{ backgroundImage: "url(" + require('../assets/images/google_icon.svg') + ")" }}></div>
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        (item.channel === "tiktok") &&
                                                                                        <div
                                                                                            className={'w-7 h-7 bg-black rounded-full p-1.5 border-2 border-white -ml-2'}>
                                                                                            <div
                                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                style={{ backgroundImage: "url(" + require('../assets/images/tiktok_icon.png') + ")" }}></div>
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        (item.channel === "twitter") &&
                                                                                        <div
                                                                                            className={'w-7 h-7 bg-twitter-500 rounded-full p-1.5 border-2 border-white -ml-2'}>
                                                                                            <div
                                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                style={{ backgroundImage: "url(" + require('../assets/images/twitter_icon.svg') + ")" }}></div>
                                                                                        </div>
                                                                                    }
                                                                                    {
                                                                                        (item.channel === "bing") &&
                                                                                        <div
                                                                                            className={'w-7 h-7 bg-bing-500 rounded-full p-1.5 border-2 border-white -ml-2'}>
                                                                                            <div
                                                                                                className="block w-full h-full bg-contain bg-no-repeat bg-center"
                                                                                                style={{ backgroundImage: "url(" + require('../assets/images/bing-logo.png') + ")" }}></div>
                                                                                        </div>
                                                                                    }
                                                                                </div>
                                                                                <div className="ml-3">
                                                                                    {item.name ? item.name : "-"}
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td className={((this.state.sort === 'client' || item.hover) ? "bg-gray-50" : "bg-white") + " border-r px-3 py-2 font-medium whitespace-no-wrap  items-center text-xs   border-gray-300"}>
                                                                            <div className="flex flex-row items-center">
                                                                                <div className="h-6 w-6 border rounded-md bg-gray-50 overflow-hidden flex justify-center items-center">
                                                                                    {
                                                                                        item.client &&
                                                                                        <img src={item.client.logo} className="bg-cover" />
                                                                                    }
                                                                                </div>
                                                                                <div className="ml-2 whitespace-no-wrap">
                                                                                    {item.client ? item.client.name : "-"}
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td className={((this.state.sort === 'spend' || item.hover) ? "bg-gray-50" : "") + " border-r px-3 py-2 font-medium cursor-pointer items-center text-xs   border-gray-300"}>
                                                                            {this.renders.convertToDecimal(item.metrics.spend)} {item.client.currency}
                                                                        </td>
                                                                        <td className={((this.state.sort === 'impressions' || item.hover) ? "bg-gray-50" : "") + " border-r px-3 py-2 font-medium cursor-pointer items-center text-xs   border-gray-300"}>
                                                                            {item.metrics.impressions ? item.metrics.impressions : "0"}
                                                                        </td>
                                                                        <td className={((this.state.sort === 'clicks' || item.hover) ? "bg-gray-50" : "") + " border-r px-3 py-2 font-medium cursor-pointer items-center text-xs   border-gray-300"}>
                                                                            {item.metrics.clicks ? item.metrics.clicks : "0"}
                                                                        </td>
                                                                        <td className={((this.state.sort === 'ctr' || item.hover) ? "bg-gray-50" : "") + " border-r px-3 py-2 font-medium cursor-pointer items-center text-xs   border-gray-300"}>
                                                                            {this.renders.convertToDecimal(item.metrics.ctr)}
                                                                        </td>
                                                                        <td className={((this.state.sort === 'cpc' || item.hover) ? "bg-gray-50" : "") + " border-r px-3 py-2 font-medium cursor-pointer items-center text-xs   border-gray-300"}>
                                                                            {this.renders.convertToDecimal(item.metrics.cpc)} {item.client.currency}
                                                                        </td>
                                                                        <td className={((this.state.sort === 'cpm' || item.hover) ? "bg-gray-50" : "") + " border-r px-3 py-2 font-medium cursor-pointer items-center text-xs   border-gray-300"}>
                                                                            {this.renders.convertToDecimal(item.metrics.cpm)} {item.client.currency}
                                                                        </td>
                                                                    </tr>
                                                                </Fragment>
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                }
                            </div>
                        }


                    </div>
                }

            </div>
        )
    }
}

export default ClientAccountList;