import React, { Component, Fragment } from 'react';
import { ChevronDownIcon, TrashIcon, ArrowLeftIcon, ArrowRightIcon, ArrowDownIcon, CheckIcon, ArrowUpIcon, CubeIcon } from '@heroicons/react/outline'
import { apiRegister } from '../services/apiRegister';
import { tokenRegister } from '../services/tokenRegister';
import TopNavigationAgent from '../moduleFiles/topNavigationAgent';
import { Menu, Transition } from '@headlessui/react';
import cn from "classnames";
import moment from "moment";
import { clientRegister } from '../services/clientRegister';
import WarningModalTailwind from '../moduleFiles/warningModalTailwind';
import SlideoutTailwind from '../moduleFiles/slideoutTailwind';
import InputTailwind from '../moduleFiles/inputTailwind';
import MultiDropdown from '../moduleFiles/multiDropdownTailwind';
import TopNavigationAgentTiny from '../moduleFiles/topNavigationAgentTiny';

class ClientWarningList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loading_partial: false,
            meta: {},
            page: 1,
            limit: 100,
            sort: "createdAt",
            direction: "descending",
            search: "",
            advancedFilters: [
                {
                    showOnTop: false,
                    multiSelect: true,
                    maxWidthFit: true,
                    type: "type",
                    placeholder: "All types (default)",
                    defaultOptions: [],
                    defaultSelected: { id: 1, name: "All types (default)", value: "all" },
                    options: [{ id: 1, name: "All types (default)", value: "all" }],
                    selected: []
                },
                {
                    showOnTop: false,
                    multiSelect: true,
                    maxWidthFit: true,
                    type: "channel",
                    placeholder: "All channels (default)",
                    defaultOptions: [],
                    defaultSelected: { id: 1, name: "All channels (default)", value: "all" },
                    options: [{ id: 1, name: "All types (default)", value: "all" }],
                    selected: []
                }
            ],
            client: {},
            types: [],
            clients: [],
            slideout: {
                search: "",
                errors: [],
                error_filter: []
            }
        };
    }

    componentWillMount() {
    }

    componentDidMount() {
        this.functions.client();
        this.functions.query();
    }

    functions = {
        query: async () => {
            let queries = window.location.search.replace("?", "").split("&");
            for (let i = 0; i < queries.length; i++) {
                if (queries[i].indexOf("client=") !== -1) {
                    let client = queries[i].replace("client=", "");
                    await this.promisedSetState({
                        client: client ? { id: client } : this.state.client
                    });
                }
            }
            this.functions.getClientWarnings(true);
        },
        getClientWarnings: async (init) => {
            await this.promisedSetState({ loading: init, loading_partial: !init, table_loading: true });
            try {
                let params = "";
                let channels = [];
                let defaultChannels = [
                    { id: 1, name: "All channels (default)", value: "all" },
                    { id: 2, name: "Google", value: "google" },
                    { id: 8, name: "Bing", value: "bing" },
                    { id: 3, name: "Facebook", value: "facebook" },
                    { id: 5, name: "LinkedIn", value: "linkedin" },
                    { id: 6, name: "Twitter", value: "twitter" },
                    { id: 7, name: "Snapchat", value: "snapchat" },
                    { id: 9, name: "TikTok", value: "tiktok" },
                ];
                this.state.advancedFilters.filter((item) => { return item.type === "type" }).map((filter) => {
                    if (filter.selected.length > 0) {
                        filter.selected.map((type) => {
                            params = params + "&type[]=" + type.value;
                        })
                    }
                });

                let response = await this.calls.getClientsWarnings(params);

                let buff = response.data.map((type) => {
                    let unique_clients = [];

                    type.warnings.forEach((warning) => {
                        if (!unique_clients.some(client => client.id === warning.client.id)) {
                            unique_clients.push(warning.client);
                        }
                    })

                    unique_clients.forEach((client) => {
                        let client_warnings = [];
                        type.warnings.forEach(warning => {
                            if (Array.isArray(warning.externalcampaignsChannels) && warning.externalcampaignsChannels.length > 0) {
                                warning.externalcampaignsChannels = warning.externalcampaignsChannels.filter((value, index, array) => array.indexOf(value) === index);
                                warning.externalcampaignsChannels.forEach(channel => {
                                    if (!channels.some(item => item === channel)) {
                                        channels.push(channel);
                                    }
                                })
                            }
                            if (warning.client.id === client.id) {
                                client_warnings.push(warning);
                            }
                        });

                        client.warnings = client_warnings;
                    })

                    return {
                        name: type.type ? type.type.charAt(0).toUpperCase() + type.type.slice(1) : "Unknown type",
                        count: unique_clients.length,
                        clients: unique_clients,
                        updated: moment(type.last_updated).format("DD/MM/YYYY HH:mm"),
                        id: type.type,
                    }
                })

                await this.promisedSetState({
                    types: buff
                })
                console.log(this.state.types, "=============TYPES");

                if (this.state.types.length > 0) {
                    this.state.meta.total = this.state.types.length
                    let advancedFilter_options = [...this.state.advancedFilters[0].options];
                    this.state.types.forEach((type) => {
                        if (!advancedFilter_options.some(option => option.value === type.id)) {
                            advancedFilter_options.push({ id: advancedFilter_options.length + 1, name: type.name, value: type.id });
                        }
                    });
                    this.state.advancedFilters[0].options = advancedFilter_options;
                    channels.sort().map((channel, index) => {
                        if (!this.state.advancedFilters[1].options.some(option => option.value === channel)) {
                            this.state.advancedFilters[1].options.push(defaultChannels.find(item => item.value === channel));
                            //this.state.advancedFilters[1].options.push({ id: index + 2, name: channel.charAt(0).toUpperCase() + channel.slice(1), value: channel });
                        }
                    });
                    await this.promisedSetState({
                        meta: this.state.meta,
                        advancedFilters: this.state.advancedFilters
                    })
                }
                console.log(this.state.advancedFilters, "advancedFilters");
            } catch (error) {
                console.log(error, "ERROR");
            }
            await this.promisedSetState({ loading: false, loading_partial: false, table_loading: false });
        },
        removeClientWarning: async (id) => {
            this.state.slideout.loading = true;
            await this.promisedSetState({
                slideout: this.state.slideout
            })
            try {
                await this.calls.removeClientWarning(id);
                this.state.clients = this.state.clients.map((client) => {
                    return {
                        ...client,
                        warnings: client.warnings.filter((item) => { return item.id !== id })
                    };
                })
                this.state.clients = this.state.clients.filter((client) => { return client.warnings.length > 0 });
                await this.promisedSetState({
                    clients: this.state.clients
                });
            } catch (error) {
                console.log(error)
            }
            this.state.slideout.loading = false;
            this.state.slideout.warning_id = null
            await this.promisedSetState({
                slideout: this.state.slideout,
            })
        },
        client: async () => {
            await this.promisedSetState({
                client: clientRegister.get()
            });
        },
        clientUpdated: async (client) => {
            await this.promisedSetState({
                client: client
            });
            this.functions.getClientWarnings(true);
        },
        slideoutSearch: async () => {
            if (this.state.slideout && this.state.slideout.search !== "") {
                let string = this.state.slideout.search.toLowerCase();
                this.state.clients = this.state.clients.filter(client =>
                    client.name.toLowerCase().includes(string)
                );
                await this.promisedSetState({
                    clients: this.state.clients
                })
            } else {
                if (this.state.original_clients && this.state.original_clients.length > 0) {
                    await this.promisedSetState({
                        clients: this.state.original_clients
                    })
                }
            }
        },
        sort: async (value) => {
            if (value === "count") {
                await this.promisedSetState({
                    sort: "count",
                    direction: this.state.direction === "descending" ? "ascending" : "descending"
                });
            } else if (value === "createdAt") {
                await this.promisedSetState({
                    sort: "createdAt",
                    direction: this.state.direction === "descending" ? "ascending" : "descending"
                });
            } else if (value === "type") {
                await this.promisedSetState({
                    sort: "type",
                    direction: this.state.direction === "descending" ? "ascending" : "descending"
                });
            }
            this.functions.getClientWarnings();
        }
    }

    calls = {
        getClientsWarnings: (params) => {
            let options = apiRegister.options(tokenRegister.get(), 'GET', null);
            let url = apiRegister.url.api + "/v3/adcredo/getAgencyClientsWarning?limit=" + this.state.limit + "&page=" + this.state.page + "&sortBy=" + this.state.sort + "&orderBy=" + this.state.direction + (this.state.client && this.state.client.id !== 0 ? "&client=" + this.state.client.id : "") + (this.state.search !== "" ? ("&search=" + this.state.search) : "") + (params ? params : "");
            return apiRegister.call(options, url);
        },
        removeClientWarning: (id) => {
            let options = apiRegister.options(tokenRegister.get(), 'DELETE', null);
            let url = apiRegister.url.api + "/v3/adcredo/removeAgencyClientWarning?warning=" + id;
            return apiRegister.call(options, url);
        },
    }

    renders = {};

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    promisedSetState = (newState) => {
        return new Promise((resolve) => {
            this.setState(newState, () => {
                resolve()
            });
        });
    }

    render() {

        function classNames(...classes) {
            return classes.filter(Boolean).join(' ')
        }

        return (
            <div className="relative flex flex-1 flex-col">

                <TopNavigationAgentTiny
                    onButton={(type) => {

                    }}
                    onAdvancedFilter={async (value, type) => {
                        this.state.advancedFilters = this.state.advancedFilters.map((item) => {
                            if (item.type === type) {
                                item.selected = value;
                            }
                            return item;
                        });
                        await this.promisedSetState({
                            page: 1,
                            advancedFilters: this.state.advancedFilters
                        });
                        if (type === "type") {
                            this.functions.getClientWarnings();
                        }
                    }}
                    onLimit={async (value) => {
                        await this.promisedSetState({
                            page: 1,
                            limit: value
                        })
                        this.functions.getClientWarnings();
                    }}
                    onNext={async (value) => {
                        await this.promisedSetState({
                            page: value
                        })
                        this.functions.getClientWarnings();
                    }}
                    onPrevious={async (value) => {
                        await this.promisedSetState({
                            page: value
                        })
                        this.functions.getClientWarnings();
                    }}
                    onSearch={async (value) => {
                        await this.promisedSetState({
                            loading_partial: true,
                            search: value
                        });
                        setTimeout(async () => {
                            if (value === this.state.search) {
                                await this.promisedSetState({
                                    page: 1
                                });
                                await this.functions.getClientWarnings()
                            }
                        }, 400);
                    }}
                    limit={this.state.limit}
                    page={this.state.page}
                    total={this.state.meta ? this.state.meta.total : 0}
                    showPaginaton={!this.state.loading}
                    showPaginationSearch={!this.state.loading}
                    showAdvanced={!this.state.loading}
                    advancedFilter={true}
                    advancedFilters={this.state.advancedFilters}
                    filters={[]}
                    hideUserDropdown={true}
                    buttonTypes={{}}
                    buttonLoaders={{}}
                    searchInputValue={this.state.search}
                    search={this.state.search}
                    client={{}}
                />

                {/*LOADING VIEW*/}
                {
                    this.state.loading && !this.state.loading_partial &&
                    <div className="min-h-screen flex justify-center items-center flex-col flex-1 w-full">
                        <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                        <div className="font-semibold mt-3">Loading warnings ...</div>
                    </div>
                }

                <SlideoutTailwind
                    title={this.state.slideout.type ? this.state.slideout.type : "Warnings list"}
                    submitButtonText={"Close"}
                    secondaryButton={false}
                    large={true}
                    open={this.state.slideout.open ? true : false}
                    loader={this.state.loading}
                    onCancel={() => {
                        this.state.slideout.open = false;
                        this.state.slideout.errors = [];
                        this.state.slideout.error_filter = [];
                        this.promisedSetState({ slideout: this.state.slideout });
                    }}
                    onSubmit={async () => {
                        this.state.slideout.open = false;
                        this.state.slideout.errors = [];
                        this.state.slideout.error_filter = [];
                        this.promisedSetState({ slideout: this.state.slideout });
                    }}
                >
                    <div className=' bg-white w-full overflow-hidden h-full rounded-lg'>
                        <div className={(this.state.slideout.errors.length > 1 ? "grid-cols-6 " : "grid-cols-3 ") + "grid  mb-4"}>
                            <div className="col-span-3">
                                <InputTailwind
                                    noHeight={true}
                                    search={true}
                                    label={"Search client name"}
                                    placeholder={"Search ..."}
                                    value={this.state.slideout.search}
                                    onChange={async (value) => {
                                        this.state.slideout.search = value;
                                        await this.promisedSetState({
                                            slideout: this.state.slideout
                                        });
                                        //this.functions.slideoutSearch()
                                    }}
                                />
                            </div>
                            {
                                this.state.slideout.errors.length > 1 &&
                                <div className="col-span-3 ml-2">
                                    <div className="mt-1">
                                        <MultiDropdown
                                            label={"Filter by types"}
                                            fillOut={true}
                                            small={true}
                                            locked={false}
                                            searchInput={false}
                                            placeholder={""}
                                            selected={this.state.slideout.errors.map((item, index) => {
                                                if (this.state.slideout.error_filter.some(error => error === item)) {
                                                    return { id: index + 9, name: item.charAt(0).toUpperCase() + item.slice(1) }
                                                } else {
                                                    return null;
                                                }
                                            }).filter((item) => { return item })}
                                            defaultSelected={{ id: 1, name: "All types (default)", value: "all" }}
                                            defaultOptions={[]}
                                            options={this.state.slideout.errors.map((item, index) => { return { id: index + 9, name: item.charAt(0).toUpperCase() + item.slice(1) } })}
                                            onChange={async (value) => {
                                                this.state.slideout.error_filter = value.map((item) => { return item.name.toLowerCase() });
                                                await this.promisedSetState({
                                                    slideout: this.state.slideout
                                                });
                                            }}
                                            onSearch={async (value) => {

                                            }}
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="relative overflow-x-auto bg-white rounded-md border h-full table-overflow">
                            <table className="min-w-full divide-y divide-gray-200 border-gray-300 mb-20">
                                <thead className="bg-white sticky z-51 top-0 left-0 right-0">
                                    <tr className="bg-white">
                                        <th scope='col' className='px-6 py-3 border-gray-300 text-left text-xs bg-white font-medium whitespace-nowrap text-gray-700 uppercase tracking-wider border-r' style={{ minWidth: "350px", maxWidth: "350px", width: "350px" }}>Client</th>
                                        <th scope='col' className='px-6 py-3 border-gray-300 text-left text-xs bg-white font-medium whitespace-nowrap text-gray-700 uppercase tracking-wider'>Warning</th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-gray-300">
                                    {
                                        this.state.clients.filter((item) => {
                                            if (this.state.slideout && typeof this.state.slideout.search == "string" && this.state.slideout.search !== "") {
                                                if (typeof item.name == "string") {
                                                    return item.name.toLowerCase().includes(this.state.slideout.search);
                                                } else {
                                                    return false;
                                                }
                                            } else {
                                                return true;
                                            }
                                        }).filter((item) => {
                                            if (this.state.slideout.error_filter && this.state.slideout.error_filter.length > 0) {
                                                return item.warnings.some(warning => this.state.slideout.error_filter.some(error => warning.warning.toLowerCase().includes(error)))
                                            } else {
                                                return true;
                                            }
                                        }).filter((item) => {
                                            if (this.state.advancedFilters[1].selected.length > 0 && !this.state.advancedFilters[1].selected.some(channel => channel.value === "all")) {
                                                if (this.state.advancedFilters[1].selected.map((channel) => {
                                                    if (this.state.slideout && this.state.slideout.type.toLowerCase() === "policy error") {
                                                        if (item.warnings.some(warning => warning.channel === channel.value)) {
                                                            return true;
                                                        } else {
                                                            return false;
                                                        }
                                                    } else {
                                                        if (item.warnings.some(warning => warning.level === "draft")) {
                                                            if (item.warnings.some(warning => warning.channels.some(warning_channel => warning_channel === channel.value))) {
                                                                return true;
                                                            } else {
                                                                return false;
                                                            }
                                                        } else {
                                                            if (item.warnings.some(warning => warning.externalcampaignsChannels.some(warning_channel => warning_channel === channel.value))) {
                                                                return true;
                                                            } else {
                                                                return false;
                                                            }
                                                        }
                                                    }
                                                }).filter((item) => { return item }).length > 0) {
                                                    return true
                                                } else {
                                                    return false;
                                                }
                                            } else {
                                                return true;
                                            }
                                        }).map((item, index) => {
                                            return (
                                                <tr className='border-b p-4'>
                                                    <td className="px-4 sm:px-6 py-4 font-medium items-center text-sm border-gray-300 border-r" >
                                                        <span className='sticky top-0'>
                                                            {item.name}
                                                        </span>
                                                    </td>
                                                    <td className="font-medium items-center text-sm border-gray-300">
                                                        {
                                                            item.warnings.filter((warning) => {
                                                                if (this.state.slideout.error_filter && this.state.slideout.error_filter.length > 0) {
                                                                    return this.state.slideout.error_filter.some(error => warning.warning.toLowerCase().includes(error))
                                                                } else {
                                                                    return true;
                                                                }
                                                            }).filter((item) => {
                                                                if (this.state.advancedFilters[1].selected.length > 0 && !this.state.advancedFilters[1].selected.some(channel => channel.value === "all")) {
                                                                    if (this.state.slideout && this.state.slideout.type.toLowerCase() === "policy error") {
                                                                        if (this.state.advancedFilters[1].selected.map((channel) => {
                                                                            if (item.channel === channel.value) {
                                                                                return true;
                                                                            } else {
                                                                                return false;
                                                                            }
                                                                        }).filter((item) => { return item }).length > 0) {
                                                                            return true
                                                                        } else {
                                                                            return false;
                                                                        }
                                                                    } else {
                                                                        if (item.level === "draft") {
                                                                            if (this.state.advancedFilters[1].selected.map((channel) => {
                                                                                if (item.channels.some(warning_channel => warning_channel === channel.value)) {
                                                                                    return true;
                                                                                } else {
                                                                                    return false;
                                                                                }
                                                                            }).filter((item) => { return item }).length > 0) {
                                                                                return true
                                                                            } else {
                                                                                return false;
                                                                            }
                                                                        } else {
                                                                            if (this.state.advancedFilters[1].selected.map((channel) => {
                                                                                if (item.externalcampaignsChannels.some(warning_channel => warning_channel === channel.value)) {
                                                                                    return true;
                                                                                } else {
                                                                                    return false;
                                                                                }
                                                                            }).filter((item) => { return item }).length > 0) {
                                                                                return true
                                                                            } else {
                                                                                return false;
                                                                            }
                                                                        }
                                                                    }
                                                                } else {
                                                                    return true;
                                                                }
                                                            }).map((warning, index) => {
                                                                return (
                                                                    <>
                                                                        <div className={`flex items-center p-4 ${item.warnings.length > 0 && index !== item.warnings.length - 1 && "border-b"}`}>
                                                                            <div className='flex-1 mr-4'>
                                                                                <div className='text-sm'><span className='font-bold'>{warning.level ? warning.level.charAt(0).toUpperCase() + warning.level.slice(1) : "Unknown level"}</span>: {' '}
                                                                                    <span className=''>{warning.name ? warning.name : "Name data missing"}</span>
                                                                                </div>
                                                                                {
                                                                                    Array.isArray(warning.channels) && warning.channels.length > 0 && warning.channels.some(channel => channel !== "") &&
                                                                                    <>
                                                                                        <div className='text-sm font-medium'>
                                                                                            <span className='font-semibold'>Channels: {' '}</span>
                                                                                            {
                                                                                                [...new Set(warning.channels)]
                                                                                                    .map(channel => channel.charAt(0).toUpperCase() + channel.slice(1))
                                                                                                    .join(", ")
                                                                                            }
                                                                                        </div>
                                                                                    </>
                                                                                }
                                                                                {
                                                                                    warning.channel && warning.channel !== null && warning.channel !== "" && warning.channel !== "order" &&
                                                                                    <div className='text-sm font-medium'>
                                                                                        <span className='font-semibold'>Channel: {' '}</span>
                                                                                        {warning.channel ? warning.channel.charAt(0).toUpperCase() + warning.channel.slice(1) : "Unknown channel"}
                                                                                    </div>
                                                                                }
                                                                                <div className='text-red-500'>{warning.warning ? warning.warning.charAt(0).toUpperCase() + warning.warning.slice(1) : "Warning missing"}</div>

                                                                            </div>
                                                                            <div>
                                                                                <div className='flex items-center justify-center space-x-2'>
                                                                                    <div
                                                                                        onClick={() => {
                                                                                            if (!warning || !warning.id) {
                                                                                                return;
                                                                                            }
                                                                                            let { level, type, orderId, order, user, internal_id, campaign_id, adgroup_id } = warning;
                                                                                            /*if (level === "draft") {
                                                                                                this.props.history.push("/v2/campaigns/new/channels?draft=" + (warning.id ? warning.id : warning._id));
                                                                                            } else {*/
                                                                                            let url = "";
                                                                                            if (window.location.hostname === 'localhost') {
                                                                                                url = "http://localhost:5001/v2/";
                                                                                            } else if (window.location.hostname === 'app.adcredo.io') {
                                                                                                url = "https://app.adcredo.io/v2/";
                                                                                            } else if (window.location.hostname === 'dev.adcredo.io') {
                                                                                                url = "https://dev.adcredo.io/v2/";
                                                                                            }
                                                                                            if (level === "draft") {
                                                                                                url += `campaigns/new/channels?draft=${warning.id ? warning.id : warning._id}`;
                                                                                                window.open(url);
                                                                                            } else if (level == "order" && type == "order issues") {
                                                                                                url += `orders/${orderId}/budgetplaner`;
                                                                                                window.open(url);
                                                                                            } else if (orderId && level) {
                                                                                                url += `orders/${orderId}`;
                                                                                                if (level === "campaign") {
                                                                                                    url += `?campaign=${campaign_id}`;
                                                                                                } else if (level === "adgroup") {
                                                                                                    url += `?campaign=${campaign_id}&adgroup=${internal_id}`;
                                                                                                } else if (level === "ad") {
                                                                                                    url += `?campaign=${campaign_id}&adgroup=${adgroup_id}&ad=${internal_id}`;
                                                                                                } else if (level === "order" && (type == "under delivery" || type == "over delivery")) {
                                                                                                    url += "/performance";
                                                                                                } else if (level.toLowerCase() === "keyword") {
                                                                                                    url += `?campaign=${campaign_id}&adgroup=${adgroup_id}&keyword=${internal_id}&channel=${warning.channel}`;
                                                                                                }
                                                                                                window.open(url);
                                                                                            } else if (user && level) {
                                                                                                if (level === "user" && type == "token issues") {
                                                                                                    url += "profile/connections";
                                                                                                }
                                                                                                window.open(url);
                                                                                            }
                                                                                            //}
                                                                                        }}
                                                                                        className='w-full px-4 cursor-pointer flex relative h-10 justify-center items-center rounded-md border border-gray-300 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500'>
                                                                                        Open
                                                                                    </div>
                                                                                    <div
                                                                                        onClick={async () => {
                                                                                            if (!warning || !warning.id) {
                                                                                                return;
                                                                                            }
                                                                                            this.state.slideout.warning_id = warning.id;
                                                                                            await this.promisedSetState({
                                                                                                slideout: this.state.slideout
                                                                                            })
                                                                                            this.functions.removeClientWarning(warning.id);
                                                                                        }}
                                                                                        className={`w-full px-4 cursor-pointer flex relative h-10 justify-center items-center rounded-md border border-green-600 hover:border-green-700 text-sm hover:bg-green-700 font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-600 focus:ring-green-600 bg-green-600`}>
                                                                                        {
                                                                                            this.state.slideout && this.state.slideout.loading && this.state.slideout.warning_id === warning.id ?
                                                                                                <>
                                                                                                    {
                                                                                                        <div style={{ borderTopColor: "transparent" }} class="w-4 h-4 border-2 border-white border-solid rounded-full animate-spin"></div>
                                                                                                    }
                                                                                                </>
                                                                                                :
                                                                                                "Fixed"
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div >
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </SlideoutTailwind>

                {
                    !this.state.loading &&
                    <div className="p-4 flex flex-1 flex-col">
                        <div className={"shadow bg-white w-full flex flex-col overflow-hidden h-full rounded-lg"}>
                            <div className="relative h-full flex flex-1">
                                <div className="overflow-auto absolute table-overflow w-full h-full">

                                    {/*LOADER PARTIAL*/}
                                    {
                                        this.state.loading_partial &&
                                        <div className="text-center absolute top-0 bottom-0 z-50 bg-white bg-opacity-75 right-0 left-0 flex justify-center align-middle items-center">
                                            <div style={{ borderTopColor: "transparent" }} class="w-10 h-10 border-2 border-purple-500 border-solid rounded-full animate-spin"></div>
                                        </div>
                                    }

                                    <table className="w-full relative divide-y divide-gray-300 border-gray-300">
                                        <thead className="sticky z-60 bg-white top-0 shadow">
                                            <tr>
                                                {
                                                    [{ id: 0, name: "Type", value: "type" }, { id: 1, name: "Clients", value: "count" }, { id: 3, name: "Latest warning", value: "createdAt" }].map((item, index) => {
                                                        return (
                                                            <th
                                                                onClick={async () => {
                                                                    this.functions.sort(item.value)
                                                                }}
                                                                style={{ whiteSpace: "nowrap" }}
                                                                scope="col"
                                                                className={(item.value == this.state.sort ? "bg-gray-100 bg-opacity-100 cursor-pointer" : (!item.noSort ? "bg-white hover:bg-gray-100 hover:bg-opacity-100 cursor-pointer" : "")) + " border-r px-3 py-3 border-gray-300 text-left text-xxs font-medium whitespace-nowrap text-gray-700 uppercase tracking-wider " + (index === 0 && " sticky ")}
                                                            >
                                                                <div className='flex justify-between items-center'>
                                                                    {item.name}
                                                                    {
                                                                        !item.noSort &&
                                                                        <div className="flex justify-start flex-col">
                                                                            <ArrowDownIcon className={`h-3 w-3 text-gray-900 opacity-0 ${this.state.sort === item.value && "opacity-100"} ${this.state.sort === item.value && this.state.direction === "ascending" && "transform rotate-180"}`} />
                                                                        </div>
                                                                    }
                                                                </div>

                                                            </th>
                                                        )
                                                    })
                                                }
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white tiny_table_row divide-gray-300 relative">
                                            {
                                                this.state.types.map((item, index) => {
                                                    return (
                                                        <tr
                                                            onMouseEnter={() => {
                                                                item.hover = true;
                                                                this.setState({
                                                                    types: this.state.types
                                                                })
                                                            }}
                                                            onMouseLeave={() => {
                                                                item.hover = false;
                                                                this.setState({
                                                                    types: this.state.types
                                                                })
                                                            }}
                                                            className='border-b'>
                                                            <td
                                                                onClick={async () => {
                                                                    this.state.slideout.type = item.name;
                                                                    this.state.slideout.open = true;
                                                                    let errors = [];
                                                                    item.clients.map((item) => {
                                                                        item.warnings.map((warning) => {
                                                                            let buffer = warning.warning.toLowerCase().split(",");
                                                                            buffer.map((buf) => {
                                                                                buf = buf.trim().toLowerCase();
                                                                                if (!errors.some(error => error === buf)) {
                                                                                    errors.push(buf)
                                                                                }
                                                                            })
                                                                        })
                                                                    })
                                                                    this.state.slideout.errors = errors.sort();
                                                                    await this.promisedSetState({
                                                                        clients: item.clients,
                                                                        original_clients: item.clients,
                                                                        slideout: this.state.slideout
                                                                    });
                                                                }}
                                                                key={index}
                                                                style={{ zIndex: 0 }} className={((this.state.sort === 'name' || item.hover) ? "bg-gray-50 bg-opacity-100" : "bg-white bg-opacity-100") + " sticky left-0 border-r text-left px-3 py-4 whitespace-no-wrap font-semibold cursor-pointer hover:text-purple-500 items-center text-xs border-gray-300"}>
                                                                {item.name}
                                                            </td>
                                                            <td className={((this.state.sort === 'count' || item.hover) ? "bg-gray-50" : "") + " border-r whitespace-no-wrap px-3 font-medium py-2 items-center text-xs border-gray-300"}>
                                                                {
                                                                    (this.state.advancedFilters[1].selected.length === 0 || this.state.advancedFilters[1].selected.some(channel => channel.value === "all")) &&
                                                                    item.count
                                                                }
                                                                {
                                                                    this.state.advancedFilters[1].selected.length > 0 && !this.state.advancedFilters[1].selected.some(channel => channel.value === "all") &&
                                                                    item.clients.filter((inner_item) => {
                                                                        if (this.state.advancedFilters[1].selected.length > 0 && !this.state.advancedFilters[1].selected.some(channel => channel.value === "all")) {
                                                                            if (this.state.advancedFilters[1].selected.map((channel) => {
                                                                                if (item.id === "policy error") {
                                                                                    if (inner_item.warnings.some(warning => warning.channel === channel.value)) {
                                                                                        return true;
                                                                                    } else {
                                                                                        return false;
                                                                                    }
                                                                                } else {
                                                                                    if (inner_item.warnings.some(warning => warning.level === "draft")) {
                                                                                        if (inner_item.warnings.some(warning => warning.channels.some(warning_channel => warning_channel === channel.value))) {
                                                                                            return true;
                                                                                        } else {
                                                                                            return false;
                                                                                        }
                                                                                    } else {
                                                                                        if (inner_item.warnings.some(warning => warning.externalcampaignsChannels.some(warning_channel => warning_channel === channel.value))) {
                                                                                            return true;
                                                                                        } else {
                                                                                            return false;
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }).filter((item) => { return item }).length > 0) {
                                                                                return true
                                                                            } else {
                                                                                return false;
                                                                            }
                                                                        } else {
                                                                            return true;
                                                                        }
                                                                    }).length
                                                                }
                                                            </td>
                                                            <td className={((this.state.sort === 'createdAt' || item.hover) ? "bg-gray-50" : "") + " border-r whitespace-no-wrap px-3 font-medium py-2 items-center text-xs border-gray-300"}>
                                                                {item.updated ? item.updated : ""}
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

export default ClientWarningList;